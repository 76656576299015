
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroOther from "../components/hero-other";
import ScorecardModal from "../components/scorecardModal";
import { checkPropertiesForNull } from "../utils/object";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "../components/safe-html-parser";

const GreenFeesPage = () => {

  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "green-fees" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        greenFeesPageFieldGroups {
          greenFeesAboutSection {
            description
            heading
            subHeading
            cta {
              target
              title
              url
            }
            stats {
              count
              heading
            }
            scorecardImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          greenFeesBannerSection {
            heading
            subHeading
            description
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta1 {
              target
              title
              url
            }
            cta2 {
              target
              title
              url
            }
          }
          greenFeesGallerySection {
            description
            heading
            cta {
              target
              title
              url
            }
            imageGallery {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          pricesSection {
            heading
            subHeading
            price {
              heading
              items {
                heading
                price
              }
            }
            cta {
              target
              title
              url
            }
          }
        }
      }
      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption


			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);


  const {
    pageData: { seoFieldGroups, greenFeesPageFieldGroups },
    site,
  } = data;

  const {
    greenFeesAboutSection,
    greenFeesBannerSection,
    greenFeesGallerySection,
    pricesSection,
  } = greenFeesPageFieldGroups;

  const siteUrl = site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);


	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Green Fees",
				item: {
					url: `${siteUrl}/green-fees`,
					id: `${siteUrl}/green-fees`,
				},
			},
		],
	};


  const groupedImages = greenFeesGallerySection.imageGallery;

  const gallery1 = groupedImages.slice(0, 3);
  const gallery2 = groupedImages.slice(3, 5);
  const gallery3 = groupedImages.slice(5, 7);


	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>


      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/green-fees`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {greenFeesBannerSection &&
            !checkPropertiesForNull(greenFeesBannerSection, ["heading"]) && (
              <HeroOther
                smallHeading={greenFeesBannerSection?.subHeading}
                title={greenFeesBannerSection?.heading}
                externalLink={greenFeesBannerSection?.cta1.target === "_blank"}
                button1Text={greenFeesBannerSection?.cta1?.title}
                button1Link={greenFeesBannerSection?.cta1?.url}
                button2Text={greenFeesBannerSection?.cta2?.title}
                button2Link={greenFeesBannerSection?.cta2?.url}
                description={
                  <>
                    <SafeHtmlParser
                      htmlContent={greenFeesBannerSection?.description}
                    />
                  </>
                }
                backgroundImage={
                  greenFeesBannerSection.backgroundImage.localFile
                    .childImageSharp.gatsbyImageData
                }
              />
            )}
          {pricesSection &&
            !checkPropertiesForNull(pricesSection, ["heading"]) && (
              <section className="py-5 bg-white py-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                    <Col lg={8}>
                      {pricesSection?.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {pricesSection?.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading-dark text-center mb-5 mb-lg-7 fs-1">
                        {pricesSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  {pricesSection.price && pricesSection?.price.length > 0 && (
                    <Row>
                      <Col>
                        <Row className="g-5">
                          {pricesSection?.price.map((priceItem) => (
                            <Col
                              className="text-center "
                              style={{ minHeight: "100%" }}
                              xl={4}
                              key={uuid4()}
                            >
                              <div className="  text-center  ">
                                <h3 className="text-uppercase text-dark-green fs-5 pb-3">
                                  {priceItem.heading}
                                </h3>
                              </div>
                              <div
                                style={{ height: "1px" }}
                                className="bg-dark-green mx-auto mb-3 w-60"
                              ></div>
                              {priceItem.items &&
                                priceItem.items.length > 0 && (
                                  <>
                                    {priceItem.items.map((item) => (
                                      <div key={uuid4()}>
                                        <h4 className="ssp-bold fs-6 text-dark-green">
                                          {item.heading}
                                        </h4>
                                        <p>{item.price}</p>
                                      </div>
                                    ))}
                                  </>
                                )}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {pricesSection.cta && pricesSection.cta.url && (
                    <Row>
                      <Col className="text-center mt-5 mt-lg-7">
                        <Button
                          style={{ borderRadius: "0px" }}
                          variant="primary"
                          href={pricesSection.cta?.url}
                          target={pricesSection.cta?.target ?? "_blank"}
                          rel="noreferrer"
                          className="py-3 w-100 text-white w-md-auto mb-4 mb-md-0 px-5 "
                        >
                          {pricesSection.cta?.title}
                        </Button>
                        {/* <p className="fs-5 mt-5 text-primary text-uppercase">
                      Buggies are now available to hire for £30 Please call the
                      proshop on <a href="tel:01277365747">01277 365747</a> to
                      book.
                    </p> */}
                      </Col>
                    </Row>
                  )}
                </Container>
              </section>
            )}

          {greenFeesAboutSection &&
            !checkPropertiesForNull(greenFeesAboutSection, ["heading"]) && (
              <section className="py-5 bg-primary py-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7">
                    <Col lg={5}>
                      {greenFeesAboutSection?.subHeading && (
                        <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                          {greenFeesAboutSection?.subHeading}
                        </p>
                      )}


                      <h2 className=" text-white mb-3 ">
                        {greenFeesAboutSection?.heading}
                      </h2>
                      {greenFeesAboutSection.description && (
                        <div className="text-white">
                          <SafeHtmlParser
                            htmlContent={greenFeesAboutSection.description}
                          />
                        </div>
                      )}


                      {/* <Button

										style={{ borderRadius: "0px" }}
										variant="outline-white"
										as={Link}
										to="/"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-3 px-6 "
									>
										Scorecard
									</Button> */}

                      <ScorecardModal
                        image={greenFeesAboutSection?.scorecardImage}
                        green
                        buttonText={greenFeesAboutSection.cta?.title}
                      />
                    </Col>
                    {greenFeesAboutSection.stats &&
                      greenFeesAboutSection.stats.length > 0 && (
                        <Col lg={7}>
                          <Row className="g-0">
                            {greenFeesAboutSection.stats.map((item, i) => (
                              <Col
                                className="text-center"
                                style={{
                                  borderRight:
                                    (i + 1) % 2 !== 0 ? "1px solid white" : "",
                                }}
                                xs={6}
                                key={uuid4()}
                              >
                                <p className="display-5 pb-0 mb-0 text-white merriweather">
                                  {item.count}
                                </p>
                                <p className="fs-2 text-white pb-0 mb-0">
                                  {item.heading}
                                </p>

                                {i < 2 && (
                                  <div
                                    className="my-4 w-100 bg-white"
                                    style={{ height: "1px" }}
                                  ></div>
                                )}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      )}
                  </Row>
                </Container>
              </section>
            )}
          {greenFeesGallerySection &&
            !checkPropertiesForNull(greenFeesGallerySection, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={6}>
                      <h2 className="text-primary mb-4 fs-1">
                        {greenFeesGallerySection?.heading}
                      </h2>
                      {greenFeesGallerySection?.description && (
                        <SafeHtmlParser
                          htmlContent={greenFeesGallerySection?.description}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="d-none d-md-flex">
                    <Col md={6} lg={4} className="g-3">
                      {gallery1.map((item, i) => (
                        <GatsbyImage
                          style={{ height: "310px" }}
                          className="w-100 mb-3"
                          image={item.localFile.childImageSharp.gatsbyImageData}
                          alt={item.altText}
                          key={uuid4()}
                        />
                      ))}
                    </Col>
                    <Col md={6} lg={4} className="g-3">
                      {gallery2.map((item, i) => (
                        <GatsbyImage
                          style={{
                            height:
                              i + 1 === gallery2.length ? "636px" : "310px",
                          }}
                          className="w-100 mb-3"
                          image={item.localFile.childImageSharp.gatsbyImageData}
                          alt={item.altText}
                          key={uuid4()}
                        />
                      ))}
                    </Col>
                    <Col lg={4} className="g-3 d-none d-lg-block">
                      {gallery3.map((item, i) => (
                        <GatsbyImage
                          style={{
                            height: i === 0 ? "636px" : "310px",
                          }}
                          className="w-100 mb-3"
                          image={item.localFile.childImageSharp.gatsbyImageData}
                          alt={item.altText}
                          key={uuid4()}
                        />
                      ))}
                    </Col>
                  </Row>
                  <div className="d-md-none d-grid gallery-wrapper gap-3">
                    {greenFeesGallerySection.imageGallery.slice(0,6).map((item) => (
                      <div className="gallery-item" key={uuid4()}>
                        <GatsbyImage
                          className="w-100 mb-3 d-lg-none"
                          style={{ height: "100%" }}
                          image={item.localFile.childImageSharp.gatsbyImageData}
                          alt={item.altText}
                          key={uuid4()}
                        />
                      </div>
                    ))}
                  </div>
                  {greenFeesGallerySection.cta &&
                    greenFeesGallerySection.cta?.url && (
                      <Row>
                        <Col>
                          {" "}
                          <div className="text-center">
                            <Button
                              style={{ borderRadius: "0px" }}
                              variant="outline-primary"
                              as={Link}
                              to={greenFeesGallerySection.cta?.url}
                              className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-4 px-6 "
                            >
                              {greenFeesGallerySection.cta?.title}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    )}
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );

};

export default GreenFeesPage;
