import { useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function ScorecardModal({ image, green, buttonText }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        style={{ borderRadius: "0px" }}
        variant={`outline-${green ? "white" : "primary"}`}
        onClick={handleShow}
        className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-4 px-6"
      >
        {buttonText ?? "Scorecard"}
      </Button>

      <Modal show={show} onHide={handleClose}>
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title>Scorecard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <GatsbyImage
            className="w-100"
            image={image?.localFile.childImageSharp.gatsbyImageData}
            alt={image?.altText}
          />
        </Modal.Body>
        <Modal.Footer>{/* Add your modal footer here */}</Modal.Footer>
      </Modal>
    </>
  );
}

export default ScorecardModal;
